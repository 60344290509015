import * as api from "../api";
import { StepButtonBar } from "../components/StepButtonBar";
import { TestHeader } from "../components/TestHeader";
import { VerdictIcon } from "../components/VerdictIcon";
import { GeneralTestResult, useTcuIdentity } from "../state";
import { ConnectivityParameters } from "../test-parameters";
import NetworkWifiIcon from "@mui/icons-material/NetworkWifi";
import SignalCellular0BarIcon from "@mui/icons-material/SignalCellular0Bar";
import SignalCellular1BarIcon from "@mui/icons-material/SignalCellular1Bar";
import SignalCellular2BarIcon from "@mui/icons-material/SignalCellular2Bar";
import SignalCellular3BarIcon from "@mui/icons-material/SignalCellular3Bar";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import SignalCellularConnectedNoInternet0BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import {
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";

export interface ConnectivityResult extends GeneralTestResult {
  connected: boolean | null;
  signalStrength: api.TcuConnectivity["signal_strength"];
  apiData: api.TcuConnectivity | null;
  apiError: api.ErrorInfo | null;
}

function ResultDisplay({ result }: { result: ConnectivityResult }) {
  const { t } = useTranslation();

  const connected = result.connected;
  const success = result.success === true;
  const signalStrength = result.apiData?.signal_strength ?? "Unknown";

  const connectionIcon =
    connected === true ? (
      <NetworkWifiIcon color="success" />
    ) : (
      <SignalWifiOffIcon color="error" />
    );
  let strengthIcon;
  switch (signalStrength) {
    case "Excellent":
      strengthIcon = <SignalCellular4BarIcon color="success" />;
      break;
    case "VeryGood":
      strengthIcon = <SignalCellular4BarIcon color="success" />;
      break;
    case "Good":
      strengthIcon = <SignalCellular3BarIcon color="success" />;
      break;
    case "Low":
      strengthIcon = <SignalCellular2BarIcon color="warning" />;
      break;
    case "VeryLow":
      strengthIcon = <SignalCellular1BarIcon color="warning" />;
      break;
    case "NoSignal":
      strengthIcon = <SignalCellular0BarIcon color="error" />;
      break;
    default:
      strengthIcon = (
        <SignalCellularConnectedNoInternet0BarIcon color="error" />
      );
      break;
  }

  return (
    <List>
      <ListItem divider>
        <ListItemIcon>{connectionIcon}</ListItemIcon>
        <ListItemText
          primary={t("connectivity.tcu_connected")}
          secondary={t(`yes_no_unknown.${connected}`)}
        />
      </ListItem>
      <ListItem divider>
        <ListItemIcon>{strengthIcon}</ListItemIcon>
        <ListItemText
          primary={t("connectivity.signal_strength")}
          secondary={t(`connectivity.signal_strengths.${signalStrength}`)}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <VerdictIcon success={success} error={result.apiError !== null} />
        </ListItemIcon>
        <ListItemText
          primary={t("verdict")}
          secondary={t(`boolean_verdict.${success}`)}
        />
      </ListItem>
    </List>
  );
}

export function ConnectivityStep({
  onResult,
}: {
  onResult: (result: ConnectivityResult) => void;
}) {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { boxId } = useTcuIdentity();
  const accessToken = api.useAccessToken();
  const [connectivityResult, setConnectivityResult] =
    React.useState<ConnectivityResult | null>(null);
  const [loading, setLoading] = React.useState(true);
  const doApiCall = React.useCallback(
    async (abortSignal?: AbortSignal) => {
      setLoading(true);
      try {
        const apiData = await api.checkConnectivity(
          abortSignal || null,
          accessToken,
          boxId
        );
        const connected = apiData.online;
        const success =
          apiData.rssi === null
            ? connected
            : apiData.rssi >= ConnectivityParameters.minRssi;
        setConnectivityResult({
          performedAt: DateTime.now(),
          success,
          signalStrength: apiData.signal_strength,
          connected,
          apiData: apiData,
          apiError: null,
        });
      } catch (err) {
        if (api.errIsManualAbort(err)) return;

        api.errorNotifyUser(err, { snackbar, t });

        let apiError = null;
        if (err instanceof api.ApiError) {
          apiError = err.error;
        }

        setConnectivityResult({
          performedAt: DateTime.now(),
          success: false,
          signalStrength: "Unknown",
          connected: null,
          apiData: null,
          apiError: apiError,
        });
      }

      setLoading(false);
    },
    [boxId, t, snackbar, accessToken]
  );
  React.useEffect(() => {
    if (connectivityResult !== null) return;

    const abortController = new AbortController();
    doApiCall(abortController.signal);
    return () => abortController.abort();
  }, [doApiCall, connectivityResult]);

  let content;
  if (loading || !connectivityResult) {
    content = (
      <Box sx={{ p: 3 }}>
        <Typography gutterBottom>{t("waiting_for_answer")}</Typography>
        <LinearProgress />
      </Box>
    );
  } else {
    content = <ResultDisplay result={connectivityResult} />;
  }

  const success = connectivityResult?.success ?? false;

  return (
    <Box>
      <TestHeader testKey="connectivity" />
      <Paper elevation={1} sx={{ my: 3 }}>
        {content}
      </Paper>
      <StepButtonBar
        onContinue={() => {
          if (connectivityResult) onResult(connectivityResult);
        }}
        onRetry={() => {
          doApiCall();
        }}
        success={success}
        disabled={loading}
      />
    </Box>
  );
}
