import * as api from "./api";
import { AirSuspensionResult } from "./steps/AirSuspension";
import { ChargingResult } from "./steps/Charging";
import { ConnectivityResult } from "./steps/Connectivity";
import { GpsResult } from "./steps/Gps";
import { DateTime } from "luxon";
import React from "react";

export interface TcuIdentity {
  readonly vin: string;
  readonly boxId: string;
}

export const TcuIdentityContext = React.createContext<TcuIdentity | null>(null);

export function useTcuIdentity(): TcuIdentity {
  const tcuId = React.useContext(TcuIdentityContext);
  if (tcuId === null) throw new Error("no tcu identity context found");
  return tcuId;
}

export interface GeneralTestResult {
  readonly performedAt: DateTime | null;
  readonly success: boolean | null;
}

const STEPS_IN_ORDER = [
  "identify",
  "connectivity",
  "charging",
  "gps",
  "air-suspension",
  "output-results",
] as const;
const FIRST_STEP = STEPS_IN_ORDER[0];
const LAST_STEP = STEPS_IN_ORDER[STEPS_IN_ORDER.length - 1];
type Step = (typeof STEPS_IN_ORDER)[number];

function getNextStepInOrder(step: Step): Step {
  if (step === LAST_STEP) return LAST_STEP;
  return STEPS_IN_ORDER[STEPS_IN_ORDER.indexOf(step) + 1];
}

function advanceStateStep(
  prevState: State,
  stateMods: Omit<Partial<State>, "step" | "startAt" | "endAt" | "tests">,
  testsMods?: Partial<TestResults>
): State {
  let { startAt, endAt, step: prevStep, tests } = prevState;
  if (prevStep === FIRST_STEP && startAt === null) {
    // when completing the first step, set the start time
    startAt = DateTime.now();
  }
  const step = getNextStepInOrder(prevStep);
  if (step === LAST_STEP && endAt === null) {
    // when completing the penultimate step, set the end time
    endAt = DateTime.now();
  }
  if (testsMods !== undefined) tests = { ...tests, ...testsMods };

  return {
    ...prevState,
    ...stateMods,
    step,
    startAt,
    endAt,
    tests,
  };
}
function advanceStateCompleteTest<K extends keyof TestResults>(
  prevState: State,
  key: K,
  value: TestResults[K]
): State {
  return advanceStateStep(prevState, {}, { [key]: value });
}

export interface TestResults {
  readonly connectivity: ConnectivityResult | null;
  readonly charging: ChargingResult | null;
  readonly gps: GpsResult | null;
  readonly airSuspension: AirSuspensionResult | null;
}

export interface State {
  readonly step: Step;
  readonly vin: string | null;
  readonly boxId: string | null;
  readonly startAt: DateTime | null;
  readonly endAt: DateTime | null;
  readonly tests: TestResults;
}

export function createInitialState(): State {
  return {
    step: FIRST_STEP,
    vin: null,
    boxId: null,
    startAt: null,
    endAt: null,
    tests: {
      connectivity: null,
      charging: null,
      gps: null,
      airSuspension: null,
    },
  };
}

export type Action =
  | {
      type: "reset";
    }
  | {
      type: "tcu-identified";
      data: TcuIdentity;
    }
  | { type: "connectivity-result"; data: ConnectivityResult }
  | { type: "charging-result"; result: ChargingResult }
  | { type: "gps-result"; result: GpsResult }
  | { type: "airSuspension-result"; result: AirSuspensionResult };

export function stateReducer(state: State, action: Action): State {
  switch (action.type) {
    case "reset":
      return createInitialState();
    case "tcu-identified":
      return advanceStateStep(state, {
        vin: action.data.vin,
        boxId: action.data.boxId,
      });
    case "connectivity-result":
      return advanceStateCompleteTest(state, "connectivity", action.data);
    case "charging-result":
      return advanceStateCompleteTest(state, "charging", action.result);
    case "gps-result":
      return advanceStateCompleteTest(state, "gps", action.result);
    case "airSuspension-result":
      return advanceStateCompleteTest(state, "airSuspension", action.result);
  }
}

export function createEolReport(state: State): api.EolReport | null {
  const { vin, boxId, startAt, endAt, tests } = state;

  if (vin === null || boxId === null || startAt === null || endAt === null)
    return null;

  const passed = Object.values(tests).every((res) => res?.success === true);

  return {
    vin,
    box_id: boxId,
    start_at: startAt,
    end_at: endAt,
    passed,
    tests,
  };
}
