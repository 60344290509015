export const AirSuspensionParameters = {
  heightToleranceCm: 1.0,
  expectedRaisedHeightCm: 47.0,
  expectedLoweredHeightCm: 42.0,
};

export const ChargingParameters = {
  signalUpdateIntervalMs: 10000,
  socThreshold: 90,
  powerTolerance: 200,
  powerCommandDuration: 60 * 20,
  startChargingPower: 2500,
  stopChargingPower: 0,
  unlockChargingPower: 11000,
  unlockCommandDuration: 24 * 60 * 60,
};

export const GpsParameters = {
  maxGpsDop: 20.0,
};

export const ConnectivityParameters = {
  minRssi: 10.0,
};
