import { createInitialState, stateReducer, TcuIdentityContext } from "./state";
import { AirSuspensionStep } from "./steps/AirSuspension";
import { ChargingStep } from "./steps/Charging";
import { ConnectivityStep } from "./steps/Connectivity";
import { GpsStep } from "./steps/Gps";
import { IdentifyStep } from "./steps/Identify";
import { OutputResultsStep } from "./steps/OutputResults";
import React from "react";

export function TestRun(): JSX.Element {
  const [state, dispatch] = React.useReducer(
    stateReducer,
    createInitialState()
  );
  const tcuId = React.useMemo(() => {
    if (state.boxId === null || state.vin === null) return null;
    return { boxId: state.boxId, vin: state.vin };
  }, [state.boxId, state.vin]);

  let stepContent;
  switch (state.step) {
    case "identify":
      stepContent = (
        <IdentifyStep
          onTcuIdentified={(data) => dispatch({ type: "tcu-identified", data })}
        />
      );
      break;
    case "connectivity":
      stepContent = (
        <ConnectivityStep
          onResult={(data) => dispatch({ type: "connectivity-result", data })}
        />
      );
      break;
    case "charging":
      stepContent = (
        <ChargingStep
          onResult={(result) => dispatch({ type: "charging-result", result })}
        />
      );
      break;
    case "gps":
      stepContent = (
        <GpsStep
          onResult={(result) => dispatch({ type: "gps-result", result })}
        />
      );
      break;
    case "air-suspension":
      stepContent = (
        <AirSuspensionStep
          onResult={(result) =>
            dispatch({ type: "airSuspension-result", result })
          }
        />
      );
      break;
    case "output-results":
      stepContent = (
        <OutputResultsStep
          onNewTest={() => dispatch({ type: "reset" })}
          state={state}
        />
      );
      break;
  }

  return (
    <TcuIdentityContext.Provider value={tcuId}>
      {stepContent}
    </TcuIdentityContext.Provider>
  );
}
