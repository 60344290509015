import * as api from "../api";
import { TestHeader } from "../components/TestHeader";
import { TcuIdentity } from "../state";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";

const VIN_CHARS_RE = /^[A-HJ-NPR-Z0-9]+$/;
const VIN_LENGTH = 17;

type ValidateVinError =
  | "invalid_length"
  | "invalid_character"
  | "tcu_not_found";

function validateVin(vin: string, full: boolean): ValidateVinError | null {
  if (vin.length === 0) return null;

  if (!VIN_CHARS_RE.test(vin)) {
    return "invalid_character";
  }

  const validLength = full
    ? vin.length === VIN_LENGTH
    : vin.length <= VIN_LENGTH;
  if (!validLength) return "invalid_length";

  return null;
}

interface Props {
  onTcuIdentified: (id: TcuIdentity) => void;
}

export function IdentifyStep({ onTcuIdentified }: Props) {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const accessToken = api.useAccessToken();
  const [errId, setErrId] = React.useState<ValidateVinError | null>(null);
  const [vin, setVin] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const doSubmit = React.useCallback(() => {
    if (loading || vin === null) return;

    setLoading(true);
    api
      .tcuInfoForVin(null, accessToken, vin)
      .then((info) => {
        setLoading(false);
        if (info.found && info.box_id !== null) {
          onTcuIdentified({
            vin,
            boxId: info.box_id,
          });
        } else {
          setErrId("tcu_not_found");
        }
      })
      .catch((err) => {
        setLoading(false);
        api.errorNotifyUser(err, { snackbar, t });
      });
  }, [accessToken, loading, onTcuIdentified, snackbar, t, vin]);

  const startEnabled = errId === null && vin !== null;

  return (
    <Box>
      <TestHeader testKey="identify" />
      <Stack spacing={2} sx={{ my: 3 }}>
        <TextField
          autoFocus
          error={errId !== null}
          helperText={
            errId !== null ? t(`identify.vin_validation.${errId}`) : null
          }
          label={t("identify.vin_long")}
          onBlur={() => {
            if (vin !== null && vin.length > 0) {
              setErrId(validateVin(vin, true));
            }
          }}
          onChange={(ev) => {
            const newVin = ev.target.value.toUpperCase();
            setVin(newVin);
            setErrId(validateVin(newVin, false));
          }}
          onKeyUp={(ev) => {
            if (ev.key === "Enter" && startEnabled) doSubmit();
          }}
          placeholder="WF0ABCDEFGHI12345"
          required
          variant="outlined"
        />
        <LoadingButton
          disabled={!startEnabled}
          loading={loading}
          onClick={doSubmit}
          size="large"
          variant="contained"
        >
          {t("identify.start_test")}
        </LoadingButton>
      </Stack>
    </Box>
  );
}
