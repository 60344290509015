import { TestResults } from "./state";
import { DateTime } from "luxon";
import { ProviderContext as SnackbarContext } from "notistack";
import { useAuth } from "react-oidc-context";

export function useAccessToken(): string {
  const auth = useAuth();
  const user = auth.user ?? null;
  if (user === null) throw new Error("not signed in");
  return user.access_token;
}

export interface ErrorInfo {
  type: string;
  description: string;
}

interface ErrorResponse {
  error: ErrorInfo;
}

export function responseIsError(resp: any): resp is ErrorResponse {
  return Object.hasOwn(resp, "error");
}

export function errIsManualAbort(err: any): boolean {
  return err instanceof DOMException && err.name === "AbortError";
}

export function errorNotifyUser(
  err: any,
  {
    snackbar,
    t,
    background,
  }: {
    snackbar: SnackbarContext;
    t: (id: string) => string;
    background?: boolean;
  }
): void {
  let errId = "unknown";
  if (err instanceof ApiError) {
    switch (err.error.type) {
      case "AuthRequired":
      case "AuthTokenInvalid":
      case "AuthTokenExpired":
        errId = "auth";
        break;
      case "ResponseTimeout":
        errId = "timeout";
        break;
    }
  }
  console.error("api error", err);
  snackbar.enqueueSnackbar(t(`api_error.${errId}`), {
    variant: background === true ? "warning" : "error",
    preventDuplicate: true,
  });
}

export class ApiError extends Error {
  error: ErrorInfo;

  constructor(info: ErrorInfo) {
    super(`api error: ${info.description}`);

    this.error = info;
  }
}

function fetchOptions(
  abortSignal: AbortSignal | null,
  accessToken: string
): RequestInit {
  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Authorization", `Bearer ${accessToken}`);
  headers.append("Content-Type", "application/json");

  return {
    cache: "no-cache",
    headers,
    signal: abortSignal,
  };
}

export interface TcuInfo {
  found: boolean;
  box_id: string | null;
}

export async function tcuInfoForVin(
  abortSignal: AbortSignal | null,
  accessToken: string,
  vin: string
): Promise<TcuInfo> {
  const resp = await fetch(
    `/api/tcu/by-vin/${vin}`,
    fetchOptions(abortSignal, accessToken)
  );
  const data = await resp.json();
  if (responseIsError(data)) throw new ApiError(data.error);
  return data;
}

export interface TcuConnectivity {
  online: boolean;
  rssi: number | null;
  uptime: number | null;
  signal_strength:
    | "Unknown"
    | "Excellent"
    | "VeryGood"
    | "Good"
    | "Low"
    | "VeryLow"
    | "NoSignal";
}

export async function checkConnectivity(
  abortSignal: AbortSignal | null,
  accessToken: string,
  boxId: string
): Promise<TcuConnectivity> {
  const resp = await fetch(
    `/api/tcu/${boxId}/connectivity`,
    fetchOptions(abortSignal, accessToken)
  );
  const data = await resp.json();
  if (responseIsError(data)) throw new ApiError(data.error);
  return data;
}

export interface TcuSignal {
  value: number | null;
  lastReceivedAt: DateTime | null;
  changedAt: DateTime | null;
}

export function emptyTcuSignal(): TcuSignal {
  return {
    value: null,
    lastReceivedAt: null,
    changedAt: null,
  };
}

function tcuSignalFromApi(data: any): TcuSignal {
  let lastReceivedAt = data.last_received_at ?? null;
  if (lastReceivedAt !== null)
    lastReceivedAt = DateTime.fromISO(lastReceivedAt);
  let changedAt = data.changed_at ?? null;
  if (changedAt !== null) changedAt = DateTime.fromISO(changedAt);
  return {
    value: data.value ?? null,
    lastReceivedAt,
    changedAt,
  };
}

export async function getSignalValue(
  abortSignal: AbortSignal | null,
  accessToken: string,
  boxId: string,
  signal: string
): Promise<TcuSignal> {
  const resp = await fetch(
    `/api/tcu/${boxId}/signal/${signal}`,
    fetchOptions(abortSignal, accessToken)
  );
  const data = await resp.json();
  if (responseIsError(data)) throw new ApiError(data.error);
  return tcuSignalFromApi(data);
}

export async function getMultipleSignalValues(
  abortSignal: AbortSignal | null,
  accessToken: string,
  boxId: string,
  signals: string[]
): Promise<{ [signal: string]: TcuSignal }> {
  const url = new URL(`/api/tcu/${boxId}/signals`, window.location.href);
  for (const signal of signals) {
    url.searchParams.append("signal", signal);
  }

  const resp = await fetch(url, fetchOptions(abortSignal, accessToken));
  const data = await resp.json();
  if (responseIsError(data)) throw new ApiError(data.error);

  return signals.reduce((d, key) => {
    d[key] = tcuSignalFromApi(data[key] ?? {});
    return d;
  }, {} as { [signal: string]: TcuSignal });
}

async function checkPostResponse(resp: Response): Promise<void> {
  // we expect 204 - no response
  if (resp.ok) return;

  const data = await resp.json();
  if (responseIsError(data)) throw new ApiError(data.error);
  else throw new Error("unexpected api error response");
}

export async function setMaxAcPower(
  abortSignal: AbortSignal | null,
  accessToken: string,
  boxId: string,
  maxAcPower: number,
  duration: number
): Promise<void> {
  const resp = await fetch(`/api/tcu/${boxId}/power`, {
    ...fetchOptions(abortSignal, accessToken),
    method: "POST",
    body: JSON.stringify({
      max_ac_power: maxAcPower,
      duration,
    }),
  });
  await checkPostResponse(resp);
}

export async function resendStatusSignals(
  abortSignal: AbortSignal | null,
  accessToken: string,
  boxId: string
): Promise<void> {
  const resp = await fetch(`/api/tcu/${boxId}/resend-status-signals`, {
    ...fetchOptions(abortSignal, accessToken),
    method: "POST",
  });
  await checkPostResponse(resp);
}

export interface TcuPosition {
  signal: boolean;
  signal_strength:
    | "Unknown"
    | "Ideal"
    | "Excellent"
    | "Good"
    | "Moderate"
    | "Fair"
    | "Poor";
  time_to_fix: number | null;
  latitude: number | null;
  longitude: number | null;
  height: number | null;
  dilution_of_precision: number | null;
  satellites: number | null;
}

export async function getTcuPosition(
  abortSignal: AbortSignal | null,
  accessToken: string,
  boxId: string
): Promise<TcuPosition> {
  const resp = await fetch(
    `/api/tcu/${boxId}/position`,
    fetchOptions(abortSignal, accessToken)
  );
  const data = await resp.json();
  if (responseIsError(data)) throw new ApiError(data.error);
  return data;
}

export interface EolReport {
  vin: string;
  box_id: string;
  start_at: DateTime;
  end_at: DateTime;
  passed: boolean;
  tests: TestResults;
}

export async function uploadEolReport(
  abortSignal: AbortSignal | null,
  accessToken: string,
  report: EolReport
): Promise<string> {
  const resp = await fetch("/api/eol-report", {
    ...fetchOptions(abortSignal, accessToken),
    method: "POST",
    body: JSON.stringify(report),
  });
  const data = await resp.json();
  if (responseIsError(data)) throw new ApiError(data.error);
  return data["id"];
}
