import { StepButtonBar } from "../components/StepButtonBar";
import { TestHeader } from "../components/TestHeader";
import { VerdictIcon } from "../components/VerdictIcon";
import { GeneralTestResult } from "../state";
import { AirSuspensionParameters } from "../test-parameters";
import HeightIcon from "@mui/icons-material/Height";
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";

export interface AirSuspensionResult extends GeneralTestResult {
  raised: AirSuspensionHeightResult | null;
  lowered: AirSuspensionHeightResult | null;
  disabledPassed: boolean | null;
}

export function AirSuspensionStep({
  onResult,
}: {
  onResult: (result: AirSuspensionResult) => void;
}) {
  const { t } = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initState());

  let stepContent;
  switch (state.step) {
    case 0:
      stepContent = (
        <SuspensionCheckSubstep
          key="raise"
          translationKey="raise"
          expectedHeight={AirSuspensionParameters.expectedRaisedHeightCm}
          onNext={(result) =>
            dispatch({
              type: "finish-raise",
              result,
            })
          }
        />
      );
      break;
    case 1:
      stepContent = (
        <SuspensionCheckSubstep
          key="lower"
          translationKey="lower"
          expectedHeight={AirSuspensionParameters.expectedLoweredHeightCm}
          onNext={(result) =>
            dispatch({
              type: "finish-lower",
              result,
            })
          }
        />
      );
      break;
    case 2:
      stepContent = (
        <DisabledSubstep
          onNext={(result) =>
            dispatch({
              type: "finish-disabled",
              result,
            })
          }
        />
      );
      break;
    case 3:
      stepContent = <VerdictDisplay state={state} />;
      break;
    default:
      throw new Error("unreachable step");
  }

  const canContinue = [state.raised, state.lowered].every(
    (res) => res !== null
  );
  const success =
    state.raised?.passed === true && state.lowered?.passed === true;

  return (
    <Box>
      <TestHeader testKey="air_suspension" />
      <Stepper activeStep={state.step} sx={{ mt: 5 }}>
        <Step>
          <StepLabel>{t("air_suspension.raise.step_name")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("air_suspension.lower.step_name")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("air_suspension.disabled.step_name")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("air_suspension.verdict.step_name")}</StepLabel>
        </Step>
      </Stepper>
      <Paper elevation={1} sx={{ my: 2, p: 3 }}>
        {stepContent}
      </Paper>
      <StepButtonBar
        onContinue={() =>
          onResult({
            raised: state.raised,
            lowered: state.lowered,
            disabledPassed: state.disabledPassed,
            performedAt: DateTime.now(),
            success,
          })
        }
        onSkip={() =>
          onResult({
            raised: state.raised,
            lowered: state.lowered,
            disabledPassed: state.disabledPassed,
            performedAt: DateTime.now(),
            success: null,
          })
        }
        onRetry={() => {
          dispatch({ type: "reset" });
        }}
        success={canContinue}
        disabled={false}
      />
    </Box>
  );
}

interface State {
  step: number;
  raised: AirSuspensionHeightResult | null;
  lowered: AirSuspensionHeightResult | null;
  disabledPassed: boolean | null;
}

function initState(): State {
  return {
    step: 0,
    raised: null,
    lowered: null,
    disabledPassed: null,
  };
}

type Action =
  | { type: "reset" }
  | { type: "finish-raise"; result: AirSuspensionHeightResult }
  | { type: "finish-lower"; result: AirSuspensionHeightResult }
  | { type: "finish-disabled"; result: boolean };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "reset":
      return initState();
    case "finish-raise":
      return { ...state, step: state.step + 1, raised: action.result };
    case "finish-lower":
      return { ...state, step: state.step + 1, lowered: action.result };
    case "finish-disabled":
      return { ...state, step: state.step + 1, disabledPassed: action.result };
  }
}

interface AirSuspensionHeightResult {
  measuredHeightCm: number;
  expectedHeightCm: number;
  passed: boolean;
}

function SuspensionCheckSubstep({
  translationKey,
  expectedHeight: expectedHeightCm,
  onNext,
}: {
  translationKey: "raise" | "lower";
  expectedHeight: number;
  onNext: (result: AirSuspensionHeightResult) => void;
}) {
  const { t } = useTranslation();
  const [errorKey, setErrorKey] = React.useState<
    "invalid_input" | "offset_too_large" | null
  >(null);
  const [heightCm, setHeightCm] = React.useState<number | null>(null);
  const handleUserInput = React.useCallback(
    (raw: string) => {
      const rawParsed = Number.parseFloat(raw);
      if (Number.isNaN(rawParsed)) {
        setHeightCm(null);
        if (raw === "") {
          setErrorKey(null);
        } else {
          setErrorKey("invalid_input");
        }
        return;
      }

      setHeightCm(rawParsed);
      const offsetCm = Math.abs(rawParsed - expectedHeightCm);
      if (offsetCm <= AirSuspensionParameters.heightToleranceCm) {
        setErrorKey(null);
      } else {
        setErrorKey("offset_too_large");
      }
    },
    [setHeightCm, setErrorKey, expectedHeightCm]
  );

  return (
    <>
      <Typography gutterBottom variant="body1">
        {t(`air_suspension.${translationKey}.instructions`)}
      </Typography>
      <Box sx={{ my: 5 }}>
        <TextField
          error={errorKey !== null}
          placeholder={expectedHeightCm.toLocaleString()}
          helperText={
            errorKey !== null
              ? t(`air_suspension.errors.${errorKey}`, { expectedHeightCm })
              : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HeightIcon />
              </InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9.]*",
          }}
          onChange={(ev) => handleUserInput(ev.target.value)}
        />
      </Box>
      <Button
        color={errorKey === null ? undefined : "error"}
        disabled={heightCm === null}
        onClick={() => {
          if (heightCm !== null)
            onNext({
              measuredHeightCm: heightCm,
              expectedHeightCm: expectedHeightCm,
              passed: errorKey === null,
            });
        }}
      >
        {t("next_step")}
      </Button>
    </>
  );
}

function DisabledSubstep({ onNext }: { onNext: (passed: boolean) => void }) {
  const { t } = useTranslation();
  const [airSuspensionControllable, setAirSuspensionControllable] =
    React.useState(false);

  return (
    <>
      <Typography gutterBottom sx={{ whiteSpace: "pre-wrap" }} variant="body1">
        {t("air_suspension.disabled.instructions")}
      </Typography>
      <Box sx={{ my: 5 }}>
        <FormHelperText>{t("air_suspension.disabled.prompt")}</FormHelperText>
        <FormControlLabel
          control={
            <Switch
              color="error"
              onChange={(ev) => setAirSuspensionControllable(ev.target.checked)}
              checked={airSuspensionControllable}
            />
          }
          label={t(`yes_no_unknown.${airSuspensionControllable}`)}
        />
      </Box>
      <Button
        color={airSuspensionControllable === true ? "error" : undefined}
        onClick={() => {
          const passed = airSuspensionControllable === false;
          onNext(passed);
        }}
      >
        {t("next_step")}
      </Button>
    </>
  );
}

function VerdictDisplay({ state }: { state: State }) {
  const { t } = useTranslation();

  const raisedPassed = state.raised?.passed === true;
  const loweredPassed = state.lowered?.passed === true;
  const disabledPassed = state.disabledPassed === true;

  return (
    <>
      <Typography gutterBottom variant="body1">
        {t("air_suspension.verdict.instructions")}
      </Typography>
      <List>
        <ListItem divider>
          <ListItemIcon>
            <VerdictIcon success={raisedPassed} />
          </ListItemIcon>
          <ListItemText
            primary={t("air_suspension.raise.step_name")}
            secondary={t(`boolean_verdict.${raisedPassed}`)}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <VerdictIcon success={loweredPassed} />
          </ListItemIcon>
          <ListItemText
            primary={t("air_suspension.lower.step_name")}
            secondary={t(`boolean_verdict.${loweredPassed}`)}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <VerdictIcon success={disabledPassed} />
          </ListItemIcon>
          <ListItemText
            primary={t("air_suspension.disabled.step_name")}
            secondary={t(`boolean_verdict.${disabledPassed}`)}
          />
        </ListItem>
      </List>
    </>
  );
}
